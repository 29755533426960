"use client";

import { FC } from "react";

const GoogleTranslateAttribution: FC = () => {
  return (
    <svg
      width="49"
      height="16"
      viewBox="0 0 49 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.15999 5.58594V7.22461H10.082C9.96466 8.14661 9.65732 8.81994 9.18932 9.28794C8.61799 9.85861 7.72532 10.4879 6.15999 10.4879C3.74466 10.4879 1.85732 8.54194 1.85732 6.12727C1.85732 3.71261 3.74466 1.76661 6.15999 1.76661C7.46199 1.76661 8.41332 2.27861 9.11599 2.93727L10.272 1.78061C9.29133 0.844607 7.98932 0.127274 6.15999 0.127274C2.85266 0.127274 0.071991 2.81994 0.071991 6.12727C0.071991 9.43461 2.85266 12.1273 6.15999 12.1273C7.94466 12.1273 9.29132 11.5419 10.3453 10.4446C11.428 9.36127 11.7647 7.83927 11.7647 6.60994C11.7647 6.22994 11.7353 5.87861 11.6767 5.58594H6.15999Z"
        fill="#757575"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.6667 4.25461C14.5253 4.25461 12.7793 5.88328 12.7793 8.12727C12.7793 10.3566 14.5253 11.9999 16.6667 11.9999C18.8087 11.9999 20.554 10.3566 20.554 8.12727C20.554 5.88328 18.8087 4.25461 16.6667 4.25461ZM16.6667 10.4739C15.4933 10.4739 14.4807 9.50594 14.4807 8.12727C14.4807 6.73394 15.4933 5.77994 16.6667 5.77994C17.84 5.77994 18.8527 6.73394 18.8527 8.12727C18.8527 9.50594 17.84 10.4739 16.6667 10.4739Z"
        fill="#757575"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.72 5.12061H35.6613C35.2793 4.66528 34.546 4.25461 33.622 4.25461C31.686 4.25461 30 5.94127 30 8.12727C30 10.2979 31.686 11.9999 33.622 11.9999C34.546 11.9999 35.2793 11.5893 35.6613 11.1199H35.72V11.6613C35.72 13.1426 34.928 13.9346 33.6513 13.9346C32.61 13.9346 31.9647 13.1866 31.7007 12.5559L30.2193 13.1719C30.6447 14.1986 31.774 15.4606 33.6513 15.4606C35.6467 15.4606 37.3333 14.2873 37.3333 11.4266V4.45394H35.72V5.12061ZM33.7693 10.4739C32.596 10.4739 31.702 9.47661 31.702 8.12727C31.702 6.76328 32.596 5.77994 33.7693 5.77994C34.9287 5.77994 35.8373 6.77794 35.8373 8.14194C35.8373 9.49127 34.9287 10.4739 33.7693 10.4739Z"
        fill="#757575"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.3333 4.25461C23.192 4.25461 21.446 5.88328 21.446 8.12727C21.446 10.3566 23.192 11.9999 25.3333 11.9999C27.4753 11.9999 29.2207 10.3566 29.2207 8.12727C29.2207 5.88328 27.4753 4.25461 25.3333 4.25461ZM25.3333 10.4739C24.16 10.4739 23.148 9.50594 23.148 8.12727C23.148 6.73394 24.16 5.77994 25.3333 5.77994C26.5067 5.77994 27.5193 6.73394 27.5193 8.12727C27.5193 9.50594 26.5067 10.4739 25.3333 10.4739Z"
        fill="#757575"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.6667 0.287277H40.34V11.9999H38.6667V0.287277Z"
        fill="#757575"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45.51 10.4739C44.6447 10.4739 44.0287 10.0786 43.632 9.30127L48.81 7.15928L48.634 6.71927C48.3113 5.85394 47.3287 4.25461 45.3193 4.25461C43.324 4.25461 41.6667 5.82461 41.6667 8.12727C41.6667 10.2986 43.3093 11.9999 45.51 11.9999C47.2847 11.9999 48.3113 10.9146 48.7367 10.2833L47.4167 9.40328C46.9767 10.0493 46.3753 10.4739 45.51 10.4739ZM45.384 5.70594C46.0727 5.70594 46.656 6.05661 46.8507 6.55994L43.3533 8.00994C43.3533 6.37727 44.508 5.70594 45.384 5.70594Z"
        fill="#757575"
      />
    </svg>
  );
};

export default GoogleTranslateAttribution;
